import angular from 'angular';
import cBillsEditTab from './cBillsEditTab';
import cBillsViewTab from './cBillsViewTab';
import './styles.css';

const moduleName = 'com.farzoom.pear.cBills';


angular.module(moduleName, [
        'ui.mask',
        'com.farzoom.common.cAttributes',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCBillsEditTab', cBillsEditTab)
    .directive('fzpCBillsViewTab', cBillsViewTab)


export default moduleName;