const ctrl = ['$scope', '$sanitize', function ($scope, $sanitize) {
    const my = this;

    const re = /({.+?})/g;

    my.main = null;
    my.showTable = false;

    function getValueText(key) {
        switch (key.type) {
            case 'string': return key.value;
            case 'number': return key.value;
            case 'dateMonth': return key.value;
            case 'boolean': return key.value ? 'ДА' : 'НЕТ';
        }
        return key.value;
    };

    my.scoringStyles = function (key) {
        switch(key.negative) {
            case true:
                return "background: #FFF1F8; color: #F22136; border: 1px solid #e0bbb5;" // red
            case false:
                return "background: #F5FFE6; color: #24b51c; border: 1px solid #c8ddb4;" // green
            default:
                return "background: #E1F8FE; color: #24b51c; border: 1px solid #b2d6e4;" // blue
        }
    }

    my.addKey = function (node, k, recursive) {
        const keysMap = $scope.model.keys;

        const key = angular.copy(keysMap[k]);
        key.key = k;
        key.parent = node;

        key.hasMax = (Object.keys(key).indexOf('max') > -1);
        if (key.hasMax) {
            for (let x = key.parent; !!x && !x.childHasMax; x = x.parent) {
                x.childHasMax = true;
            }
        }

        key.hasOriginal = (Object.keys(key).indexOf('original') > -1);
        if (key.hasOriginal) {
            key.typeOfOriginal = typeof key.original;
        }

        if (key.formula) {
            const formula = $sanitize(key.formula);
            const subKs = formula.match(re);

            let tmpl = `<span class="fz-c-scoring__formula">${k} = ${formula}</span>`;
            subKs.forEach(x => {
                const xd = x.substr(1, x.length - 2);
                const keyObj = keysMap[xd];
                const name =  (keyObj && keyObj.name) || ''
                tmpl = tmpl.replace(x, `<a href ng-click="my.addKey(node, '${xd}')" title="${name}: ${xd} = ${getValueText(keyObj)}" class="c-scoring__key">${xd}</a>`);
                if (recursive) my.addKey(key, xd, recursive);
            });

            key.tmpl = tmpl;
        } else {
            key.tmpl = `<span class="fz-c-scoring__formula">${k}</span>`
        }

        node.children = node.children || [];
        if (!node.children.some(x => x.key === k)) {
            node.children.push(key);
        }
    };

    my.removeKey = function (node) {
        const index = node.parent.children.indexOf(node);
        node.parent.children.splice(index, 1);
    };

    $scope.$watch('model', function () {
        if (!$scope.model) return;
        my.main = {};
        const recursive = true;
        $scope.model.main.forEach(k => my.addKey(my.main, k, recursive));
    });
}];

export default ctrl;