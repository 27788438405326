import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.data = angular.copy($modalParams.item);
    my.ref = $modalParams.ref;
    my.readonly = !!$modalParams.readonly;

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.isGuarantor = function() {
        return my.data.productTypeRefId==='guarantor'
    };

    my.isAvalOfBills = function() {
        return my.data.productTypeRefId==='avalOfBills'
    }

    my.isPledge = function() {
        return my.data.productTypeRefId==='pledge'
    }

    my.isCredit = function() {
        return my.data.productTypeRefId==='credit'
    }

    my.isCreditLine = function() {
        return my.data.productTypeRefId==='creditLine'
    }

    my.isBankGuarantee = function() {
        return my.data.productTypeRefId==='bankGuarantee'
    }

    my.isBankGuaranteeLine = function() {
        return my.data.productTypeRefId==='bankGuaranteeLine'
    }

    my.isLoan = function() {
        return my.data.productTypeRefId==='loan'
    }

    my.getModalTitle = function() {
        switch (my.data.productTypeRefId) {
            case 'loan':
                return "Займодавец"
            case 'bankGuarantee':
            case 'bankGuaranteeLine':
                return "Гарант"
            case 'credit':
            case 'creditLine':
                return "Кредитор"
            default:
                return "Наименование заимодавца"
        }

    }
}];