import tmpl from './template.html';
import ctrl from './controller';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            companyId: '='
        },
        controller: ctrl,
        controllerAs: 'my',
        template: tmpl
    };
};

export default cmp;
