import tmpl from './tab-client.html';
import tabClientCtrl from './tabClientCtrl';
import './styles.css';

const tabClient = function () {
    return {
        restrict: 'E',
        scope: {
            companyId: '=?'
        },
        controller: tabClientCtrl,
        controllerAs: '$ctrl',
        template: tmpl
    };
};

export default tabClient;
