const ctrl = ['$scope', 'fzpSvcGuarantorCompanyEdit', function ($scope, fzpSvcGuarantorCompanyEdit) {
    const my = this;

    my.showModal = function () {
        fzpSvcGuarantorCompanyEdit.open(
            $scope.model,
            $scope.suggestPersons,
            $scope.ngReadonly
        ).then(result => {
            if (!result) return;
            $scope.onChange({
                $item: result
            });
        });
    };

    my.validate = function () {
        if (!$scope.model) return true;

        return true; // TODO
    };
}];

export default ctrl;
