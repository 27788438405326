const ctrl = function ($scope) {
    const my = this;
    $scope.uniqueId = new Date().getTime() + (Math.random() * 100000000000000000).toString(36);

    my.removeValue = function (values, index) {
        values.splice(index, 1);
    };

    my.isValidValue = (value, pattern) => new RegExp(pattern).test(value)
};
ctrl.$inject = ['$scope'];
export default ctrl;