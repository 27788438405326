export default ['$modalInstanse', '$modalParams', function ($modalInstanse, $modalParams) {
    const my = this;
    my.selectedLot = null;
    my.lots = $modalParams.lots;

    my.closeCancel = function () {
        $modalInstanse.close({
            resultCode: 'CANCEL'
        });
    };

    my.closeOk = function () {
        $modalInstanse.close({
            resultCode: 'OK',
            selectedLots: my.lots.filter(x => x.selected).sort((a, b) => a.number - b.number)
        });
    };
}];
