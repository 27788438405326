import angular from 'angular';
import './styles.css';

export default ['$modalInstanse', '$modalParams', function ($modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.data = angular.copy($modalParams.model);
}];