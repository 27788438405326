import angular from 'angular';
import component from './fzpCProductBgEdit';

const moduleName = 'com.farzoom.pear.fzpCProductBgEdit';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCProductBgEdit', component)

export default moduleName;
