import angular from 'angular';
function formatYear(n){
    switch ('' + (n % 10)){
      case '1':{
        return 'год'
      }
        case '2':
        case '3':
        case '4':{
        return 'года'
      }
      default:{
        return 'лет'
      }
    }
  }
  
  function formatDay(n){
    switch ('' + (n % 10)){
      case '1':{
        return 'день'
      }
        case '2':
        case '3':
        case '4':{
        return 'дня'
      }
      default:{
        return 'дней'
      }
    }
  }
  
  
  function formatLast(n){
    switch ('' + (n)){
      case '1':{
        return 'Последний'
      }
      default:{
        return 'Последние'
      }
    }
  }

  function formatNear(n){
    switch ('' + (n)){
      case '1':{
        return 'ближайший'
      }
      default:{
        return 'ближайшие'
      }
    }
  }
export class FzpCExperience{
    constructor($scope, experienceSvc){
        this.$scope = $scope;
        this.experienceSvc = experienceSvc;
        
        this.state = {
            dataLoading: false,
            dataLoaded: false,
            data: undefined
        };

        this.$scope.$watch('orderId', () => {
            this.loadData();
        });
    }

    loadData(){
        const orderId = (this.$scope && this.$scope.model) ? this.$scope.model.orderId : undefined;
        if(!orderId) return; // throw new Error
        this.state.dataLoading = true;
        this.state.dataLoaded = false;
        this.experienceSvc.getExperienceByOrderId(orderId).then(data => {
            this.state.data = data.result;
            this.state.dataLoading = false;
            this.state.dataLoaded = true;
        }).catch(err => {
            this.state.data = undefined;
            this.state.dataLoaded = true;
            this.state.dataLoading = false;
        });
    }

    getDurationText(n){
        return `${formatLast(n)} ${n} ${formatYear(n)}`
    }

    getExpireSoonTitle(n){
        return `контракт истечет в ${formatNear(n)} ${n} ${formatDay(n)}`
    }

    


}

FzpCExperience.$inject = ['$scope', 'fzpSvcExperience'];


export default FzpCExperience;