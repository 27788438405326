import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.getRefName = function (refName, value) {
        if (!value) return 'нет сведений';
        const m = $scope.model;
        if (!m || !m.ref || !m.ref[refName]) return null;
        const refItem = m.ref[refName].find(x => x.value === value) || {};
        return refItem.name;
    }

    my.state = {
        itemsInvalid: []
    }

    my.showModal = function () {
        fzModal.open({
            windowClass: 'fz-c-management__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                header: $scope.model.header,
                config: $scope.model.config,
                itemTemplate: $scope.model.itemTemplate,
                data: angular.copy($scope.model.data),
                ref: $scope.model.ref,
                suggestPersons: $scope.suggestPersons
            }
        }).closed.then(result => {
            if (!result) return;
            $scope.model.data = result;
        });
    };

    my.validate = function () {
        const x = $scope.model;
        if (!x || !x.data) return false;
        const d = x.data;
        const config = x.config;

        var validAddressAndTaxationType = true;

        if (d.soleExecutiveBodyRefId == 'managementCompany' && !!d.managementCompany) {
           validAddressAndTaxationType = ((!config.managementCompany.actualAddress.required)||(config.managementCompany.actualAddress.required && !!d.managementCompany.actualAddress)) &&
           ((!config.managementCompany.taxationType.required) || (config.managementCompany.taxationType.required  && !!d.managementCompany.taxationTypeRefId));
        }

        return !!d.supremeGoverningBody &&
            !!d.soleExecutiveBodyRefId &&
            (d.soleExecutiveBodyRefId !== 'managementCompany' || !!d.managementCompany) &&
            (!d.hasSupervisoryBoard || (
                !!d.supervisoryBoardName &&
                !!d.supervisoryBoardMembers && d.supervisoryBoardMembers.length 
            )) &&
            (!d.hasCollegialExecutiveBody || (
                !!d.collegialExecutiveBodyName &&
                !!d.collegialExecutiveBodyMembers && d.collegialExecutiveBodyMembers.length 
            )) &&
            validAddressAndTaxationType;

    };
}];

export default ctrl;
