const _MS_PER_DAY = 1000 * 60 * 60 * 24;

function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

function calcDuration(start, end) {
    if (!start || !end) return null;
    if (start > end) return null;
    const a = new Date(start);
    const b = new Date(end);

    return dateDiffInDays(a, b) + 1;
};

export default ['$scope', '$http', '$timeout', 'fzSvcNumber', function ($scope, $http, $timeout, fzSvcNumber) {
    const my = this;

    // debounce prevents from calling fnc several times in a row
    let timeoutHandler;

    function debounce(fnc, debounceTimeout) {
        if (timeoutHandler) $timeout.cancel(timeoutHandler);
        timeoutHandler = $timeout(fnc, debounceTimeout);
    };

    $scope.$watch('model', refreshAmount);
    $scope.$watch('bgOrder', refreshAmount, true);
    $scope.$watch('bgOrderParams', refreshAmount, true);
    $scope.$watch('company', refreshAmount);

    $scope.$watch('model.calcAmount', refreshPercent);
    $scope.$watch('model.amount', refreshPercent);

    function refreshAmount() {
        debounce(() => {
            if (!$scope.model || $scope.ngReadonly || !$scope.model.canEdit || $scope.model.hideCalcCommission) return;
            calc($scope.bgOrder, $scope.model.frontendServiceLocation).then(commissionAmount => {
                if (!$scope.model.calcAmount || $scope.model.calcAmount != commissionAmount)
                $scope.model.calcAmount = commissionAmount;
            });
        }, 300);
    };

    function refreshPercent() {
        if (!$scope.model || !$scope.model.calcAmount) return;
        if ($scope.model.hideCalcCommission) {
            $scope.model.calcDeltaPercent = 1;
            return;
        }
        $scope.model.calcDeltaPercent = !$scope.model.amount ? 1 : $scope.model.amount / $scope.model.calcAmount;
        if ($scope.decreaseReasonPercent && $scope.decreaseReason){
            $scope.decreaseReason.isRequired = $scope.model.calcDeltaPercent < (1 - $scope.decreaseReasonPercent/100);
        }
    }

    function round(value) {
        // return fzSvcNumber.ceil5000(value); // округление до 50 рублей вверх (5000 копеек)
        return fzSvcNumber.ceil10(value, 2); // округление до 1 рубля вверх (100 копеек)
    }

    function calc(order, serviceLocation) {
        const request = {};
        if (((order || {}).data || {}).purchase) {
            // NEW PRODUCT
            request.guaranteeAmount = (order.data.purchase.lots || []).flatMap(lot => lot.beneficiaries || []).map(ben => ben.bgAmount || 0).reduce((a, b) => a + b);
            request.startDate = new Date(order.data.startDate);
            request.guaranteeDurationDays = calcDuration(order.data.startDate, order.data.endDate);
            request.product = order;
        } else {
            // LEGACY
            request.guaranteeAmount = order.guaranteeAmount;
            request.startDate = new Date();
            request.guaranteeDurationDays = order.guaranteeDurationDays;
        }

        return $http.post(serviceLocation, request)
            .then(function (resp) {
                return round((resp.data || {}).commissionAmount);
            });
    };

    my.isValidPercent = () => {
        return !$scope.model ||
            !$scope.model.minPercent ||
            !$scope.model.calcDeltaPercent ||
            parseInt(($scope.model.calcDeltaPercent - 1) * 10000) >= parseInt($scope.model.minPercent * 100)
    }

    my.isValidAmount = () => {
        return !$scope.model ||
            !$scope.model.minAmount ||
            !$scope.model.amount ||
            $scope.model.amount >= parseInt($scope.model.minAmount)
    }

    my.isValid = () => {
        return $scope.ngReadonly || !$scope.model.canEdit || (my.isValidAmount() && my.isValidPercent());
    }

    refreshAmount();
}];