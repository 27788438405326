import controller from './controller';
import template from './template.html';

export function createModal(data, params){
    params = params || {};
    return {
        windowClass: params.windowClass || '',
        template: template,
        controller: controller,
        controllerAs: 'my',
        params: {
            header: 'Технические подробности',
            data: data
        }
    }
}

export default createModal;