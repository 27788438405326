const ctrl = function ($scope, fzSvcAttribute, $filter) {
    const my = this;
    const toMoney = $filter('fzToMoney');
    const toDate = val => $filter('date')(val, 'dd.MM.yyyy');

    my.model = {};
    my.attributeContext = null;

    my.evaluateExpression = function (expressionString) {
        if (!my.attributeContext) return;
        return fzSvcAttribute.evaluateExpression(expressionString, my.attributeContext);
    };

    $scope.$watch('model', function (model) {
        if (model) {
            my.attributeContext = fzSvcAttribute.createContext(model);
            if (model.attributes) {
                my.model = model.attributes;
            } else {
                my.model = model;
            }
        }
    });

    function getRefVaue(value, ref) {
        return ((ref || []).find(x => x.value === value) || {}).name || value;
    }

    my.extractValue = (type, value, ref) => {
        if (!type || !value) return '';
        switch (type) {
            case 'address':
                return (value.addressValue || {}).result;
            case 'bank': {
                if (!value.bankValue) return '';
                return `${value.bankValue.bankName} (БИК: ${value.bankValue.bic})`;
            }
            case 'money':
                return !value.moneyValue ? '' : toMoney(value.moneyValue);
            case 'date':
                return !value.dateValue ? '' : toDate(value.dateValue);
            case 'boolean':
                return !value.booleanValue ? 'Нет' : 'Да';
            case 'booleanWithComment': {
                const b = !value.booleanValue ? 'Нет' : 'Да';
                const s = value.stringValue;
                return !s ? b : `${b} (${s})`;
            }
            default: {
                if (!ref) return value[`${type}Value`];
                else return getRefVaue(value[`${type}Value`], ref);
            }
        }
    };
};

ctrl.$inject = ['$scope', 'fzpSvcAttribute', '$filter'];
export default ctrl;
