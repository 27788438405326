import tmpl from './template.html';
import './styles.css';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            model: '='
        },
        template: tmpl
    };
};

export default cmp;
