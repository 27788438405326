import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  col2: {
    width: '16.66%',
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col2 = ({ children, className }) => {
  const classes = useStyles()
  return <div className={classnames(classes.col2, className)}>{children}</div>
}
