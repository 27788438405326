import tmpl from './template.html';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            hasChange: '=?',
            header: '@?',
            ngReadonly: '=?',
            ngRequired: '=?',
        },
        template: tmpl
    };
};

export default cmp;
