import angular from 'angular'
import 'ngreact'
import {EditSas} from "../../react/page/EditSas";

const moduleName = 'com.farzoom.pear.reactEditSas'

angular
    .module(moduleName, ['react'])
    .directive(
        'reactEditSas',
        (reactDirective) => reactDirective(EditSas, ['model', 'onChange'])
    )

export default moduleName
