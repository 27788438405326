const ctrl = ['$scope', 'fzpSvcAttribute', function ($scope) {
    const my = this;

    if ($scope.config.spouse && $scope.config.spouse.show) {
        my.spouseConfig = angular.copy($scope.config || {});
        my.spouseConfig.maritalStatus = {show: false};
    }

    my.onChangePerson = function (newItem) {
        $scope.model.person = newItem;
        if ($scope.config.spouse && $scope.config.spouse.show) {
            if ($scope.model.person.maritalStatus === "married") {
                $scope.model.spouse = {
                    person: {
                        maritalStatus: "married"
                    }
                };
            } else {
                $scope.model.spouse = null;
            }
        }
        $scope.onChange({
            $item: $scope.model
        });
    };

    my.onChangeSpouse = function (newItem) {
        $scope.model.spouse.person = newItem;
        $scope.onChange({
            $item: $scope.model
        });
    };

}];

export default ctrl;
