import React, {forwardRef} from 'react'
import InputMask from 'react-input-mask'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {Icon} from "../../Icon";
import {Label} from "../Label";

const useStyles = createUseStyles({
    inputWrapper: {
        background: '#fff',
        width: '100%',
        height: 35,
        borderRadius: 8,
        display: 'inline-block',
        verticalAlign: 'top',
        position: 'relative',
        '&.label': {
            height: 60,
            '& input:not([type="checkbox"])': {
                paddingTop: 28,
            },
        },

        '& input:not([type="checkbox"])': {
            height: '60px !important'
        },

        '&.disabled': {
            background: '#DDDDDD',
        },
        '&.required': {
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: -6,
                top: 5,
                bottom: 5,
                width: 2,
                background: '#3742FA',
            },
        },
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        padding: [0, 20, 12],
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        // border: 'none',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.25,
        color: '#242232',
        display: 'block',
        borderRadius: 'inherit',
        border: [1, 'solid', '#DDDDDD'],
        caretColor: '#3742FA',
        transition: 'border 300ms ease',
        '&:-moz-placeholder': {
            color: 'rgba(36, 34, 50, 0.6)',
        },
        '&::-moz-placeholder': {
            color: 'rgba(36, 34, 50, 0.6)',
        },
        '&:-ms-input-placeholder': {
            color: 'rgba(36, 34, 50, 0.6)',
        },
        '&::-webkit-input-placeholder': {
            color: 'rgba(36, 34, 50, 0.6)',
        },
        '&::-webkit-text-fill-color': {
            color: 'rgba(36, 34, 50, 0.6)',
        },
        '&:hover': {
            borderColor: '#CBCBCB',
        },
        '&:focus': {
            borderColor: '#3742FA',
        },
        '&.error': {
            borderColor: '#FF3F34 !important',
        },
        '&.icon': {
            paddingRight: 42,
        },
    },
    error: {
        margin: [3, 20, 0],
        color: 'red',
        fontSize: 12,
        pointerEvents: 'none',
        // right: 20,
        // bottom: 13,
        // position: 'absolute',
    },
    icon: {
        width: 18,
        height: 18,
        stroke: '#504E5B',
        display: 'block',
        position: 'absolute',
        top: 8,
        right: 12,
        pointerEvents: 'none',
    },
    measure: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.25,
        color: '#242232',
        letterSpacing: '0.05em',
        position: 'absolute',
        bottom: 12,
        right: 20,
        zIndex: 3
    },
    defValue: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: 1.25,
        color: '#C1876B',
        letterSpacing: '0.05em',
        position: 'absolute',
        bottom: 0,
        right: 10,
        pointerEvents: 'none',
        zIndex: 3
    },
    description: {
        paddingTop: 5,
        fontSize: 10,
        lineHeight: 1.2,
        letterSpacing: '0.05em',
        color: '#242232',
    },
})

export const Input = forwardRef(
    (
        {
            children,
            label,
            id,
            className,
            errorClassName,
            value,
            error,
            required,
            mask,
            maskChar,
            alwaysShowMask,
            formatChars,
            icon,
            iconClassName,
            measure,
            defValue,
            iconClick,
            description,
            disabled,
            ...rest
        },
        ref,
    ) => {
        const classes = useStyles()
        const inputProps = {
            id,
            className: classnames(classes.input, {icon, error}),
            value: value || '',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            autoComplete: 'off',
            ref,
            ...rest,
        }
        return (
            <div style={{width: '100%'}}>
                <div
                    className={classnames(
                        classes.inputWrapper,
                        {label, required, disabled},
                        className,
                    )}
                >

                    {label &&
                    <Label htmlFor={id}>
                        {label}
                    </Label>
                    }

                    {mask
                        ? <InputMask
                            {...inputProps}
                            mask={mask}
                            maskChar={maskChar}
                            alwaysShowMask={alwaysShowMask}
                            formatChars={formatChars}
                            disabled={disabled}
                        />
                        : <input {...inputProps} disabled={disabled}/>
                    }

                    {icon &&
                    <Icon
                        className={iconClassName || classes.icon}
                        iconName={icon}
                        onClick={iconClick}
                    />
                    }

                    {measure &&
                    <div className={classes.measure}>
                        {measure}
                    </div>
                    }

                    {defValue &&
                    <div className={classes.defValue}>
                        {defValue}
                    </div>
                    }

                    {children}

                </div>

                {description &&
                <div className={classes.description}>
                    {description}
                </div>
                }

                {error &&
                <div className={classnames(classes.error, errorClassName)}>
                    {error}
                </div>
                }
            </div>
        )
    },
)
