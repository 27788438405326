import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.OnInit = function () {
        if ($scope.model.data.paymentSchedule.length < $scope.model.config.minPayments) {
            my.onButtonAddClickHandler();
        }
    };

    my.isReadyOnly = function () {
        return !$scope.model.config.canEdit;
    }

    my.addNewPaymentsToSchedule = function () {
        let quantityPayments = $scope.model.data.paymentSchedule.length;
        // Расчет даты нового платежа, если предыдущий платеж существует, то дата нового +1 месяц
        let dueDate = !quantityPayments
            ? moment().format("yyyy-MM-DD")
            : moment($scope.model.data.paymentSchedule[quantityPayments - 1].dueDate)
                .add(1,'M').format("yyyy-MM-DD");

        $scope.model.data.paymentSchedule.push({
            dueDate,
            amount: 0,
        });
    }

    my.onButtonAddClickHandler = function () {
        if (!$scope.model) $scope.model = {};
        if (!$scope.model.data) $scope.model.data = {};
        if (!$scope.model.data.paymentSchedule) $scope.model.installment.paymentSchedule = [];
        if (!$scope.model.config.canEdit) return;

        my.addNewPaymentsToSchedule();
        while ($scope.model.data.paymentSchedule.length < $scope.model.config.minPayments){
            my.addNewPaymentsToSchedule();
        }

        if ($scope.model.data.paymentSchedule.length > $scope.model.config.minPayments) {
            my.lessThanMinPayments = false;
        }
    };

    my.onButtonDeleteClickHandler = function (item) {
        const index = $scope.model.data.paymentSchedule.indexOf(item);
        const minPayments = $scope.model.config.minPayments;
        if ($scope.model.data.paymentSchedule.length - 1 < minPayments) {
            my.lessThanMinPayments = true;
            return;
        }
        $scope.model.data.paymentSchedule.splice(index, 1);
    };

    my.getErrorMessage = function () {
        return "Количество платежей не может быть меньше " + $scope.model.config.minPayments;
    }

    my.isShowEditButtons = function() {
        return !!$scope.showEditButtons;
    }

    my.isTotalAmountSet = function() {
        return !!$scope.totalAmount;
    }

    my.getRemainingPayment = function (amountIndex) {
        let remaining = $scope.model.data.paymentSchedule.reduce((remaining, payment, arrayIndex) => {
            return (arrayIndex <= amountIndex ? remaining - payment.amount : remaining)
        }, $scope.totalAmount);

        $scope.model.data.paymentSchedule[amountIndex].remaining = remaining.toFixed(0);

        return remaining;
    }

    my.isAmountExceeded = function () {
        if ($scope.model.data.paymentSchedule.length < 1) return false;
        return my.getRemainingPayment($scope.model.data.paymentSchedule.length - 1) < 0;
    }

    my.OnInit();
}];

export default ctrl;