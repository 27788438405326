import angular from 'angular';
import fzpCPayments from './fzpCPayments';
import service from './fzpSvcPayments';
const moduleName = 'com.farzoom.pear.cPayments';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCPayments', fzpCPayments)
    .service('fzpSvcPayments', service)

export default moduleName;
