import './styles.css';

export default ['$scope', '$q', '$http', '$modalInstanse', '$modalParams', 'fzFilesConfig', function ($scope, $q, $http, $modalInstanse, $modalParams, fzFilesConfig) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.person);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.removeContact = function (item) {
        const index = my.person.contacts.indexOf(item);
        if (index > -1) my.person.contacts.splice(index, 1);
    };

    my.addContact = function () {
        my.person.contacts.push({});
    };

    function show(fld) {
        return !!((my.config || {})[fld] || {}).show;
    }

    my.onSelect = function (item) {
        if (item) {
            const refData = angular.copy(item.data);

            if (show('inn')) my.person['inn'] = refData['inn'];
            my.person['lastName'] = refData['lastName'];
            my.person['firstName'] = refData['firstName'];
            my.person['secondName'] = refData['secondName'];

            if (show('birthDate')) my.person['birthDate'] = refData['birthDate'];
            if (show('birthPlace')) my.person['birthPlace'] = refData['birthPlace'];
            if (show('identityDocument')) {
                my.person['series'] = refData['series'];
                my.person['number'] = refData['number'];
                my.person['issuingAuthority'] = refData['issuingAuthority'];
                my.person['issuingAuthorityCode'] = refData['issuingAuthorityCode'];
                my.person['issuedDate'] = refData['issuedDate'];
            } 
            if (show('registrationAddress')) my.person['registrationAddress'] = refData['registrationAddress'];
            if (show('actualAddress')) my.person['actualAddress'] = refData['actualAddress'];

            // contacts
            if ((show('phone') || show('email')) && refData['contacts']) {
                my.person['contacts'] = [];
                refData['contacts'].forEach(x => {
                    const contact = {};
                    if (show('phone')) contact['phone'] = x['phone'];
                    if (show('email')) contact['email'] = x['email'];
                    my.person['contacts'].push(contact);
                });
                // Always show at least one line of contacts
                if (!my.person['contacts'].length) my.person['contacts'].push({});
            }

            if (show('position')) my.person['position'] = refData['position'];
            if (show('authorizingDoc')) my.person['authorizingDoc'] = refData['authorizingDoc'];
            if (show('citizenship')) my.person['citizenship'] = refData['citizenship'];
            if (show('otherCitizenship')) my.person['otherCitizenship'] = refData['otherCitizenship'];
            if (show('sharePercent')) my.person['sharePercent'] = refData['sharePercent'];
            if (show('shareSum')) my.person['shareSum'] = refData['shareSum'];

            fillAttributes(my.person.personAttributes, refData.personAttributes);
            fillAttributes(my.person.relationAttributes, refData.relationAttributes);
            fillAttributes(my.person.relationTopAttributes, refData.relationTopAttributes);
        }
    };

    function fillAttributes(destAttrs, srcAttrs) {
        if (destAttrs && destAttrs.items && destAttrs.items.length && srcAttrs && srcAttrs.items && srcAttrs.items.length) {
            const destMap = new Map();
            destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr));
            srcAttrs.items.forEach(attr => {
                if (destMap.has(attr.attributeId)) {
                    const destAttr = destMap.get(attr.attributeId);
                    destAttr.value = attr.value;
                    destAttr.values = attr.values;
                }
            });
        }
    };

    my.isRf = function () {
        const isShown = my.config && my.config.citizenship && my.config.citizenship.show;
        return !isShown || my.person.citizenship === '643'; //'rf';
    };

    my.checkINN12 = function () {
        const isShown = my.config && my.config.inn && my.config.inn.show;
        return my.readonly || !isShown || !my.person.inn || my.person.inn.length == 12;
    };

    my.validateSharedPercent = function () {
        return my.readonly ||
            !((my.config || {}).sharePercent || {}).show ||
            !my.person.sharePercent ||
            (my.person.sharePercent >= 0 && my.person.sharePercent <= 100);
    };

    $scope.$watch('my.person.secondName', secondName => my.noSecondName = secondName === '-');

    // Passport OCR
    const passportParsingErrorCodes = {
        'FILE_TOO_LARGE': 'Размер файла превышает максимально разрешённое значение',
        'PARSE_FAILED': 'Не удалось импортировать данные',
        'UNEXPECTED': 'Произошла непредвиденная ошибка',
    };

    my.onPassportFileDrop = function (files) {
        if (!files || !files.length) return;
        const file = [...files][0];
        processPassportFile(file);
    };

    my.onPassportFileInputChange = function (input) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        clearFileInput(input);
        processPassportFile(file);
    };

    function clearFileInput(el) {
        const $el = $(el);
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    function processPassportFile(file) {
        const maxSizeInBytes = fzFilesConfig.getMaxSizeInBytes();
        my.isPassportParsing = true;
        my.isPassportParsingError = false;
        my.passportParsingMessage = '';
        return checkFileSize(file, maxSizeInBytes) // Проверить размер файла
            .then(() => parsePassportFile(file)) // Распарсить файл
            .then(data => fillPassportData(data)) // Заполнить данные на форме
            .finally(() => my.isPassportParsing = false)
            .catch(e => {
                my.isPassportParsingError = true;
                const msg = passportParsingErrorCodes[e.errorCode || 'UNEXPECTED'];
                my.passportParsingMessage = msg;
            });
    }

    function checkFileSize(file, maxSizeInBytes) {
        if (file.size > maxSizeInBytes) {
            const err = new Error("File size too large");
            err.errorCode = 'FILE_TOO_LARGE';
            return $q.reject(err);
        }
        return $q.all([]);
    }

    function parsePassportFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return $http.post('/api/ocr-passport/process_ocr', formData, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        })
            .then(resp => resp.data || {})
            .catch(e => {
                e.errorCode = 'PARSE_FAILED';
                return $q.reject(e);
            });
    }

    function fillPassportData(data) {
        const mainPage = ((data || {}).pages_list || []).find(page =>
            page.is_passport === true &&
            (page.passport_data || {}).is_main_page == true
        );
        const mainPageData = ((mainPage || {}).passport_data || {}).main_page_data || {};

        let serial_number = (mainPageData.serial_number || '').replace(/\s/g, ''); // Remove spaces
        const series = serial_number.substr(0, 4);
        const number = serial_number.substr(4, 6);

        my.person.lastName = mainPageData.surname || '';
        my.person.firstName = mainPageData.name || '';
        my.person.secondName = mainPageData.patronymic || '';
        my.person.birthDate = parseDate(mainPageData.bithday);
        my.person.birthPlace = null; // TODO
        my.person.series = series;
        my.person.number = number;
        my.person.issuedDate = parseDate(mainPageData.when_given);
        my.person.issuingAuthorityCode = mainPageData.code_given || '';
        my.person.issuingAuthority = mainPageData.where_given || '';
    }

    // Initialization

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.person = angular.copy($modalParams.person);
    my.config = $modalParams.config;
    my.ref = $modalParams.ref;
    my.suggestPersons = $modalParams.suggestPersons;

    if (!my.person) my.person = {};
    if (!my.person.contacts) my.person.contacts = [];
    if (!my.person.contacts.length) my.person.contacts.push({});
}];