import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    display: 'inline-block',
    verticalAlign: 'top',
    // '&:first-child': {
    //   '& input[type=radio]': {
    //     '& + label': {
    //       borderRadius: '6px 0 0 6px',
    //       boxShadow: '0px 6px 6px 0px hsla(0, 0%, 0%, 0.2) inset',
    //     },
    //   },
    // },
    // '&:last-child': {
    //   '& input[type=radio]': {
    //     '& + label': {
    //       borderRadius: '0 6px 6px 0',
    //       boxShadow: '0px 6px 6px 0px hsla(0, 0%, 0%, 0.2) inset',
    //     },
    //   },
    // },
    '&.error': {
      '[type=radio]': {
        '& + label': {
          borderColor: '#f00',
        },
      },
      '& + .error': {
        '&[type=radio]': {
          '& + label': {
            borderLeftColor: 'transparent',
          },
        },
      },
    },
    '& input[type=radio]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          background: '#3742fa',
          // boxShadow: '0 4px 6px 0 hsla(237, 95%, 60%, 0.4)',
          color: '#fff',
          // border-radius: 10px,
          '&:before': {
            borderColor: '#fff',
          },
          '&:after': {
            content: '""',
            background: '#fff',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            display: 'block',
            position: 'absolute',
            left: '45px',
            top: '21px',
          },
        },
      },
    },
    '& label': {
      background: '#ececf4',
      height: '100%',
      width: '100%',
      paddingLeft: '70px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'right',
      color: '#504e5b',
      padding: '18px 38px',
      letterSpacing: '0.05em',
      borderRadius: '10px',
      // border: 1px solid transparent,
      position: 'relative',
      cursor: 'pointer',
      display: 'block',
      '&:before': {
        content: '""',
        width: '20px',
        height: '20px',
        border: '1px solid rgba(80, 78, 91, 0.4)',
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        left: '40px',
        top: '16px',
      },
    },
  },
})

export const Radio = ({ id, className, label, error, ...otherProps }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.root, className, { error })}>
      <input id={id} type='radio' {...otherProps} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
