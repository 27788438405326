var tabClientCtrl = ['$scope', '$q', '$http', 'fzSvcEs', function ($scope, $q, $http, fzSvcEs) {
    var my = this;

    my.clientLogins = [];

    $scope.$watch('companyId', function () {
        if (!$scope.companyId) return;
        my.companyId = $scope.companyId;
				fzSvcEs.load('crm', 'company', my.companyId).then(function (company) {
						my.clientLogins = company.clientLogins || [];
				});
    });

    my.linkLogin = function () {
        my.editLogin = {
            login: null,
            displayName: null
        };
    };

    my.cancelLogin = function () {
        my.editLogin = null;
    };

    my.acceptLogin = function () {
        if (my.clientLogins.indexOf(my.editLogin.login) >= 0) {
            alert('Этот логин уже привязан к данному клиенту');
            return;
        }

        var data = {
            clientLogins: my.clientLogins.slice(0) // clone array
        };
        data.clientLogins.push(my.editLogin.login);

        fzSvcEs.update('crm', 'company', my.companyId, data).then(function () {
            my.clientLogins.push(my.editLogin.login);
            my.editLogin = null;
        }).catch(function () {
            alert('Не удалось сохранить данные');
        });
    };

    my.unlinkLogin = function (login) {
        my.loginToUnlink = login;
    };

    my.cancelUnlinkLogin = function () {
        my.loginToUnlink = null;
    };

    my.acceptUnlinkLogin = function () {
        var data = {
            clientLogins: my.clientLogins.slice(0) // clone array
        };
        var i = data.clientLogins.indexOf(my.loginToUnlink);
        data.clientLogins.splice(i, 1);

        fzSvcEs.update('crm', 'company', my.companyId, data).then(function () {
            my.clientLogins = data.clientLogins;
            my.loginToUnlink = null;
        }).catch(function () {
            alert('Не удалось сохранить данные');
        });
    };

}];

export default tabClientCtrl;
