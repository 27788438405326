import tmpl from './c-section.html';

const cSection = function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            header: '@',
        },
        transclude: true,
        template: tmpl,
        link: function ($scope, $element, attrs, ctrl, transclude) {
            var $title = $element.find('a.toggle');
            var $container = $element.find('div.toggle-content');

            var isActive = attrs.class.split(' ').includes('active');
            if (!isActive) {
                $container.hide();
            } else {
                $title.addClass('active');
            }

            $title.on('click', function (event) {
                event.preventDefault();

                if ($container.is(':visible')) {
                    $container.slideUp('fast', function () {
                        $title.removeClass('active');
                        $element.removeClass('active');
                    });
                } else {
                    $title.addClass('active');
                    $element.addClass('active');
                    $container.slideDown('fast', function () {
                        $container.find('[autofocus]').focus();
                    });
                }
            });

            transclude($scope.$parent, function (clone, scope) {
                $container.append(clone);
            });
        }
    }
};

export default cSection;
