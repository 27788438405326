import controller from './controller';
import template from './template.html';
import './styles.css';


const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            ngReadonly: '=?',
            ngRequired: '=?',
            hasComments: '=?'
        },
        template: template,
        controller: controller,
        controllerAs: 'my'
    };
};

export default cmp;
