import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'

const useStyles = createUseStyles({
  col12: {
    width: '100%',
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col12 = ({ children, className }) => {
  const classes = useStyles()
  return <div className={cn(classes.col12, className)}>{children}</div>
}
