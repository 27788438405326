export class FzpCVerification {
    constructor($scope, $window, $timeout) {
        this.$scope = $scope;
        this.$window = $window;
        this.$timeout = $timeout;
        this.isSwapped = false;
        this.isOpenedInModal = false;
        this.popup = undefined;
    }

    swapPanels() {
        this.isSwapped = !this.isSwapped;
    }

    openModal() {
        if (!this.popup) {
            this.isOpenedInModal = true;
            this.$window.addEventListener('message', this.onPostMessage.bind(this))
            this.popup = this.$window.open('', 'PDFViewer', "width=800,height=600,resizable=yes,scrollbars=yes,status=yes", true);

            this.popup.document.open();
            this.popup.document.write(this.getModalHtml({
                title: 'Просмотр документов',
                documentSrc: this.getDocumentUrl()
            }));
            this.popup.document.close();
            this.popup.addEventListener('beforeunload', () => {
                this.popup && !this.popup.closed && this.popup.close();
                this.onPostMessage({
                    data: 'close'
                })
            });
            this.popup.focus();
        }
    }

    onPostMessage(e) {
        if (e.data === 'close') {
            this.$window.removeEventListener('message', this.onPostMessage.bind(this))
            this.popup = undefined;
            this.$timeout(() => {
                this.isOpenedInModal = false;
            });
        }
    }

    highlightWindow() {
        if (this.popup) {
            this.popup.focus();
        }
    }

    closeModal() {
        if (this.popup && !this.popup.closed) {
            this.popup.close();
        }
    }

    isSupportPdf(){
        return Object.values(navigator.mimeTypes).findIndex(x => x.type === 'application/pdf') > -1;
    }

    getDocumentUrl(){
        var params = new URLSearchParams();
        params.append('scrollbar', 'true');
        params.append('toolbar', 'true');
        params.append('navpanes', 'true');

        return this.$scope.documentSrc + `?${params.toString()}`;
    }

    getModalHtml(data) {
        return `
<html>
    <head>
        <title>${data.title}</title>
        <style>
            html, body {
                padding: 0;
                margin: 0;
                width: 100%;
                height: 100%;
            }
            object {
                width: 100%;
                height: 100%;
                display: block;
            }
        </style>
    </head>
    <body>
        <object data="${data.documentSrc}" type="application/pdf">
            <p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${data.documentSrc}">Download PDF</a></p>
        </object>
    </body>
</html>`
    }
}

FzpCVerification.$inject = ['$scope', '$window', '$timeout']

export default FzpCVerification;