import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  col11: {
    width: '91.66%',
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col11 = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.col11}>{children}</div>
}
