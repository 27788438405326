import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.data = angular.copy($modalParams.data);
    my.header = $modalParams.header;
    console.log(my.data, angular.copy($modalParams.data))
}];