class AttributeContext {
    constructor(model) {
        this.context = model;
        this.attrsMap = new Map();
        const group = model.attributes || model;
        if (group && group.items && group.items.length) {
            group.items.forEach(attr => this.attrsMap.set(attr.attributeId, attr));
        }
    }

    static getModelValueByPath(attrId, context) {
        const fields = (attrId || '').split('.');
        let object = context;
        for (let field of fields) {
            if (!object.hasOwnProperty(field)) return undefined;
            object = object[field];
        }
        return object;
    }

    static getTypedValue(attrType, attrValue) {
        if (!attrValue) return null;
        switch (attrType) {
            case 'string':
                return attrValue.stringValue;
            case 'key':
                return attrValue.keyValue;
            case 'long':
                return attrValue.longValue;
            case 'double':
                return attrValue.doubleValue;
            case 'money':
                return attrValue.moneyValue;
            case 'date':
                return attrValue.dateValue;
            case 'dateTime':
                return attrValue.dateTimeValue;
            case 'boolean':
                return attrValue.booleanValue;
            case 'booleanWithComment':
                return attrValue.booleanValue;
        }
    }

    static getAttrValue(attr) {
        if (!attr) return null;
        const attrType = attr.type;
        if (attr.multiple) {
            return !attr.values ? [] : attr.values.map(v => AttributeContext.getTypedValue(attrType, v));
        } else {
            return AttributeContext.getTypedValue(attrType, attr.value);
        }
    }

    getValue(attrId) {
        if (!attrId || attrId === '') return undefined;
        if (this.attrsMap.has(attrId)) {
            return AttributeContext.getAttrValue(this.attrsMap.get(attrId));
        } else {
            return AttributeContext.getModelValueByPath(attrId, this.context);
        }
    }
}

export default ['$parse', function ($parse) {
    function validateAttrValue(attrType, attrValue) {
        if (!attrValue) return false;
        switch (attrType) {
            case 'string':
                return !!attrValue.stringValue;
            case 'key':
                return !!attrValue.keyValue;
            case 'long':
                return !!attrValue.longValue || attrValue.longValue === 0;
            case 'double':
                return !!attrValue.doubleValue || attrValue.doubleValue === 0;
            case 'money':
                return !!attrValue.moneyValue || attrValue.moneyValue === 0;
            case 'date':
                return !!attrValue.dateValue;
            case 'dateTime':
                return !!attrValue.dateTimeValue;
            case 'boolean':
                return attrValue.booleanValue === true || attrValue.booleanValue === false;
            case 'booleanWithComment':
                return attrValue.booleanValue === true || attrValue.booleanValue === false;
            case 'bank':
                return !!(attrValue.bankValue && attrValue.bankValue.bankName);
        }
    }

    function evaluateExpression(expressionString, attributeContext) {
        if (typeof expressionString !== 'string') return expressionString;

        const expression = $parse(expressionString);
        const attrResult = expression({
            "$": attrId => attributeContext.getValue(attrId)
        });

        return Boolean(attrResult);
    }

    function validateAttribute(attr, attributeContext) {
        const notShow = attr.showExpr && !evaluateExpression(attr.showExpr, attributeContext);
        const notRequired = !attr.required && (!attr.requiredExpr || !evaluateExpression(attr.requiredExpr, attributeContext));

        if (
            notShow || // Don't validate if attribute is not shown
            notRequired // Don't validate if attribute is not required
        ) {
            return true;
        }

        if (!attr.multiple) {
            return validateAttrValue(attr.type, attr.value);
        } else {
            if (!attr.values || !attr.values.length) return false;
            const isValid = attr.values.map(v => validateAttrValue(attr.type, v)).every(x => x);
            return isValid;
        }
    }

    function createContext(model) {
        return new AttributeContext(model);
    }

    function validateGroup(model) {
        if (!model) return true;
        const attributeContext = createContext(model);

        const group = model.attributes || model;
        if (!group || !group.items || !group.items.length) return true;
        return group.items.map(attr => validateAttribute(attr, attributeContext)).every(x => x);
    }

    this.createContext = createContext;
    this.evaluateExpression = evaluateExpression;
    this.validateGroup = validateGroup;
}];