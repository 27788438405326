import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    padding: '10px 20px 0',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#242232',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 5
  },
})

export const Label = ({ htmlFor, children }) => {
  const classes = useStyles()
  return (
    <label className={classes.root} htmlFor={htmlFor}>
      {children}
    </label>
  )
}
