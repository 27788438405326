import tmpl from './tab-agent.html';
import tabAgentCtrl from './tabAgentCtrl';
import './styles.css';

const tabAgent = function () {
    return {
        restrict: 'E',
        scope: {
            companyId: '=?'
        },
        controller: tabAgentCtrl,
        controllerAs: '$ctrl',
        template: tmpl
    };
};

export default tabAgent;
