import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(depositInfo, readonly) {
        return fzModal.open({
            windowClass: 'fzp-c-company-deposit__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                readonly: readonly,
                model: depositInfo || {}
            }
        }).closed;
    }

    this.open = open;
}];