const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.state = {};

    my.removeOkpd = function (item) {
        const index = $scope.model.okpd.indexOf(item);
        if (index > -1) $scope.model.okpd.splice(index, 1);
    };

    my.addOkpd = function () {
        if (!$scope.model.okpd) $scope.model.okpd = [];
        $scope.model.okpd.push({});
    };

    my.removeIkz = function (item) {
        const index = $scope.model.ikzList.indexOf(item);
        if (index > -1) $scope.model.ikzList.splice(index, 1);
    };

    my.addIkz = function () {
        if (!$scope.model.ikzList) $scope.model.ikzList = [];
        $scope.model.ikzList.push(null);
    };

    /**
     * 44-ФЗ. Статья 37. Антидемпинговые меры при проведении конкурса и аукциона
     * 615-ФЗ. Статья 90.
     */
    function isAntidumping() {
        const p = $scope.model;
        return p.federalLaw == '615' || (p.federalLaw == '44' &&
            p.bankGuaranteeTypeRefId == 'execution' &&
            p.purchaseMaxAmount > 1500000000); // 15 млн. рублей
    };

    function isBigDecrease() {
        // снижение на 25 и более процентов от НМЦ
        const p = $scope.model;
        return p.purchaseFinalAmount !== null && !!p.purchaseMaxAmount && p.purchaseFinalAmount / p.purchaseMaxAmount <= 0.75;
    };

    my.calcBenAmount = () => {
        if (!isAntidumping() || $scope.model.conAmount === null) return;

        if (isBigDecrease()) {
            $scope.model.guaranteeAmount = $scope.model.conAmount * 1.5;
        } else {
            $scope.model.guaranteeAmount = $scope.model.conAmount;
        }
    };

    my.validatePrepayment = function () {
        const p = $scope.model;
        return !(isAntidumping() && isBigDecrease() && p.prepaymentExists && p.guaranteeAmount < p.prepaymentAmount);
    };

    $scope.$watch('model.beneficiaryLegalAddress', function (newAddress, oldAddress) {
        if (!newAddress || !newAddress.result) return;
        if (!$scope.model.beneficiaryAddressForDocs || oldAddress && oldAddress.result === $scope.model.beneficiaryAddressForDocs) {
            $scope.model.beneficiaryAddressForDocs = newAddress.result;
        }
    });

    // Калькулируемое поле - Срок гарантии
    (function setupCalcField() {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        function dateDiffInDays(a, b) {
            // Discard the time and time-zone information.
            var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        };

        function calcDuration() {
            if (!$scope.model) return;
            let durationDays = null;
            if ($scope.model.guaranteeStartDate && $scope.model.guaranteeEndDate) {
                var a = new Date($scope.model.guaranteeStartDate);
                var b = new Date($scope.model.guaranteeEndDate);
                durationDays = dateDiffInDays(a, b) + 1;
                if (durationDays <= 0) durationDays = null;
            }
            $scope.model.guaranteeDurationDays = durationDays;
        };

        $scope.$watch('model.guaranteeStartDate', calcDuration);
        $scope.$watch('model.guaranteeEndDate', calcDuration);
    })();

    // Калькулируемое поле - %% снижения от начальной цены контракта
    (function setupCalcDeltaPrice() {
        function calcDeltaPrice() {
            if (!$scope.model) return;
            let deltaPrice = null;
            if (!$scope.model.purchaseMaxAmount || $scope.model.purchaseMaxAmount == 0) {
                deltaPrice = 0;
            } else if (!$scope.model.purchaseFinalAmount || $scope.model.purchaseFinalAmount == 0) {
                deltaPrice = 100;
            } else {
                deltaPrice = Math.round((($scope.model.purchaseMaxAmount - $scope.model.purchaseFinalAmount) / $scope.model.purchaseMaxAmount * 100) * 100) / 100;
            }
            $scope.model.deltaPrice = deltaPrice;
        };

        $scope.$watch('model.purchaseMaxAmount', calcDeltaPrice);
        $scope.$watch('model.purchaseFinalAmount', calcDeltaPrice);
    })();

    // Гарантийный период
    my.state.showIsRequiredSecurityForGuaranteePeriod = () => {
        if ($scope.model && $scope.model.config && $scope.model.config.isRequiredSecurityForGuaranteePeriod && !$scope.model.config.isRequiredSecurityForGuaranteePeriod.show) {
            return false;
        }
        return $scope.model && $scope.model.bankGuaranteeTypeRefId === 'execution';
    };

    // Бесспорное списание
    my.state.showIsRequiredIndisputableDebiting = () => {
        if ($scope.model && $scope.model.config && $scope.model.config.isRequiredIndisputableDebiting && !$scope.model.config.isRequiredIndisputableDebiting.show) {
            return false;
        }
        return $scope.model && ($scope.model.federalLaw === '44' || $scope.model.federalLaw === '223' || $scope.model.federalLaw === '615');
    };

    // Неустойки
    my.state.showIsIncludedForfeit = () => {
        if ($scope.model && $scope.model.config && $scope.model.config.isIncludedForfeit && !$scope.model.config.isIncludedForfeit.show) {
            return false;
        }
        return $scope.model && $scope.model.bankGuaranteeTypeRefId === 'execution';
    };

    // Aванс
    my.state.showPrepaymentExists = () => {
        return $scope.model && $scope.model.bankGuaranteeTypeRefId === 'execution';
    };

    my.state.isNotParticipation = () => {
        return $scope.model && $scope.model.bankGuaranteeTypeRefId !== 'participation';
    };
}];

export default ctrl;
