

import angular from 'angular';
import createTechInfoModal from './techInfoModal';

export class FzpCManualParams {
    constructor($scope, fzModal){
        this.$scope = $scope;
        this.fzModal = fzModal;
    }

    isBoolean(item){
        return item.type && item.type.toLowerCase() === 'boolean';
    }

    isMoney(item){
        return item.type && item.type.toLowerCase() === 'money';
    }

    openModal(item){
        this.fzModal.open(createTechInfoModal(item, {windowClass: 'fzp-c-manual-params-modal'})).closed.then(result => {console.log('closed')})
    }
}

FzpCManualParams.$inject = ['$scope', 'fzModal']


export default FzpCManualParams;