import tmpl from './template.html';
import ctrl from './controller';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            showTypeIcon: '=?',
            showInfo: '=?',
            showLender: '=?',
            showAmount: '=?',
            showCurrency: '=?'
        },
        template: tmpl,
        controller: ctrl,
        controllerAs: 'my'
    };
};

export default cmp;
