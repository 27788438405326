import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(subcontractor, config, ref, header, readonly) {
        return fzModal.open({
            windowClass: 'fzp-c-subcontractor-company__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                header: header,
                readonly: readonly,
                subcontractor: subcontractor || {},
                config: config || {},
                ref: ref || {}
            }
        }).closed;
    }

    this.open = open;
}];
