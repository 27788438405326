import angular from 'angular';
import templtate from './template.html'
import './contents.css';

import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const moduleName = 'com.farzoom.pear.fzpCkEditor';

(function setupCkEditor() {
    window.CKEDITOR_BASEPATH = `${process.env.WEBPACK_PUBLIC_PATH}ckeditor4/`;
    require(`!file-loader?context=${__dirname}&outputPath=ckeditor4/&name=[path][name].[ext]!./config.js`);
    require(`!file-loader?context=${__dirname}&outputPath=ckeditor4/&name=[path][name].[ext]!./contents.css`);
    require(`!file-loader?context=${__dirname}&outputPath=ckeditor4/&name=[path][name].[ext]!./styles.js`);
    require('ckeditor4');
})();

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            model: '=?'
        },
        template: templtate,
        controller: ['$scope', '$timeout', function ($scope, $timeout) {
            const my = this;

            my.active = $scope.model[0].lotNumber

            my.setActive = (activeIndex) => {
                my.active = activeIndex;
            }

            my.changeLot = (lotNumber) => {
                const findItem = $scope.model.find(item => item.lotNumber === lotNumber)
                $scope.editor.setData(findItem.content)
                my.setActive(lotNumber)
            }

            my.isActive = (index) => Number(my.active) === Number(index)

            my.formattedDateTime = (date) => date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : ''

            // debounce prevents from calling fnc several times in a row
            const DEBOUNCE_MS = 500;
            let timeoutHandler;

            function debounce(fnc, debounceTimeout) {
                if (timeoutHandler) $timeout.cancel(timeoutHandler);
                timeoutHandler = $timeout(fnc, debounceTimeout);
            };

            $scope.save = function (data) {
                debounce(() => {
                    $scope.model = data;
                }, DEBOUNCE_MS);
            }
        }],
        controllerAs: 'my',
        link: function ($scope, $element) {
            const textarea = $element.find('textarea')[0];
            $scope.editor = CKEDITOR.replace(textarea, {
                toolbar: [
                    { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
                    { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBloeditor'] },
                    { name: 'insert', items: [ 'Table' ] },
                ],
                height: '570px',
                allowedContent: true,
                resize_enabled: false,
                language: 'ru',
                removePlugins:'elementspath'
            });

            const removeMeta = (string) => {
                return string.replace(/^<meta.+/m, '').replace(/^<head.+/m, '').replace(/^<link.+/m, '').replace(/^<\/head.+/m, '');
            }
            
            $scope.editor.setData(removeMeta($scope.model[0].content))
            
            // $scope.$watch('model', newData => {
            //     if ($scope.editor.getData() !== newData) {
            //         $scope.editor.setData(newData);
            //     }
            // });
            
            $scope.editor.on('change', () => {
                const updatedModel = $scope.model.map((item, index) => {
                    if (item.lotNumber === $scope.my.active) {
                        item = {
                            ...$scope.model[index],
                            content: removeMeta($scope.editor.getData()),
                        }
                    }
                    return item;
                })
                $scope.save(updatedModel)
            });

        }
    };
};

angular.module(moduleName, [])
    .directive('fzpCkEditor', cmp)

export default moduleName;
