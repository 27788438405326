import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  switcher: {
    position: 'relative',
    '& input': {
      display: 'none',
      '&:checked': {
        '&:disabled': {
          '& + label': {
            cursor: 'default',
            '&:before': {
              background: '#2BAB8E'
            },
            '&:after': {
              background: '#2BAB8E'
            }
          }
        },
        '& + label': {
          '&.injectOpacity': {
            '&:before': {color: '#fff !important'}
          },
          '&:before': {
            background: '#3742FA',
            opacity: .5
          },
          '&:after': {
            background: '#3742FA',
            left: 17
          }
        }
      }
    }
  },
  label: {
    paddingLeft: 50,
    fontSize: 16,
    lineHeight: 1.25,
    color: ' #242232',
    fontWeight: 600,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '&.injection': {
      '&:before': {top: '-8px !important', opacity: 1},
      '&:after': {top: '-5px !important'}
    },
    '&.paddingLeft': {paddingLeft: '10px !important'},
    '&.injectOpacity': {
      '&:before': {opacity: '1 !important', color: '#999 !important'}
    },
    '&:before': {
      content: '""',
      display: 'block',
      background: '#999',
      width: 34,
      height: 14,
      opacity: 0.38,
      borderRadius: 7,
      position: 'absolute',
      left: 0,
      top: 3,
      transition: 'background 300ms ease'
    },
    '&:after': {
      content: '""',
      display: 'block',
      background: '#999',
      width: 20,
      height: 20,
      borderRadius: '50%',
      position: 'absolute',
      left: 0,
      top: 0,
      transition: 'background 300ms ease, left 300ms ease'
    }
  }
})

export const SwitchSlider = ({
                               className,
                               id,
                               label,
                               type = 'checkbox',
                               ...otherProps
                             }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.switcher, className)}>
      <input id={id} type={type} {...otherProps} />
      <label
        className={classnames(classes.label, (type === 'checkbox') ? otherProps?.injection ? 'injection injectOpacity' : 'paddingLeft injectOpacity' : '')}
        htmlFor={id}
        style={otherProps?.injection ? {paddingLeft: 115, paddingBottom: 10} : {}}>
        {label}
      </label>
    </div>
  )
}
