export class FzpSvcPayments {
    constructor($http) {
        this.$http = $http;
    }

    search(params) {
        const config = {
            params: {...params, _q: Date.now()}
        }
        return this.$http.get('/api/payment/payment', config).then(resp => {
            return resp.data;
        });
    }

    getMappedPaymentsByOrder(orderId) {
        const config = {
            params: {
                orderExternalId: orderId,
                _q: Date.now()
            }
        };
        return this.$http.get('/api/payment/paymentOrderMatch', config).then(res => {
            return res.data;
        });
    }

    deleteMappedPayment(mappedPaymentId) {
        return this.$http.delete(`/api/payment/paymentOrderMatch/${mappedPaymentId}`)
    }

    addMappedPayment(paymentId, orderId, orderNumber, matchedAmount, amount, initiatorLogin) {
        const data = {
            externalId: orderId,
            number: orderNumber,
            amount,
            initiatorLogin
        };
        const config = {
            params: {
                paymentId,
                matchedAmount
            }
        }
        return this.$http.post(`/api/payment/paymentOrderMatch`, data, config).then(res => {
            return res.data;
        })
    }
}

FzpSvcPayments.$inject = ['$http']

export default FzpSvcPayments;
