import angular from 'angular';
import fzpCManualParams from './fzpCManualParams';

const moduleName = 'com.farzoom.pear.cManualParams';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCManualParams', fzpCManualParams)

export default moduleName;
