import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(companyInfo, suggestPersons, readonly) {
        return fzModal.open({
            windowClass: 'fzp-c-guarantor-company__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                readonly: readonly,
                suggestPersons: suggestPersons,
                model: companyInfo || {}
            }
        }).closed;
    }

    this.open = open;
}];