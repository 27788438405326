import angular from 'angular';
import fzpCAddress from './fzpCAddress';
import fzpCAddresses from './fzpCAddresses';

const moduleName = 'com.farzoom.pear.fzpCAddress';

angular.module(moduleName, [
        'com.farzoom.pear.cAttributes'
    ])
    .directive('fzpCAddress', fzpCAddress)
    .directive('fzpCAddresses', fzpCAddresses)

export default moduleName;
