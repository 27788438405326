import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.model);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    $scope.$watch('my.state.selectedCompany', function (newValue) {
        if (!newValue) {
            if (my.model && my.model.company) my.model.company = {};
        } else {
            my.model.company = {
                INN: newValue.INN,
                OGRN: newValue.OGRN,
                displayName: newValue.displayName,
                fullName: newValue.fullName
            };
        }
    });

    // Initialization

    my.state = {
        selectedCompany: null
    };

    function initData() {
        if (!my.model) {
            my.model = {};
        }

        if (!my.model.company) my.model.company = {};
        my.state.selectedCompany = {
            INN: my.model.company.INN,
            OGRN: my.model.company.OGRN,
            displayName: my.model.company.displayName,
            fullName: my.model.company.fullName
        };
    };

    my.readonly = $modalParams.readonly;
    my.model = angular.copy($modalParams.model);
    initData();
}];