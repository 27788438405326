import angular from 'angular';

import cSection from './cSection';
import cSwitch from './cSwitch';
import cCompany from './cCompany';

export default angular.module('ext.cmps', [])
    .directive('cSection', cSection)
    .directive('cSwitch', cSwitch)
    .directive('fzpCompany', cCompany)
