import _ from 'lodash';

const ctrl = ['$scope', 'fzpSvcPersonEdit', 'fzpSvcGuarantorCompanyEdit', 'fzpSvcCompanyDepositEdit', 'fzpSvcPersonDepositEdit', function ($scope, fzpSvcPersonEdit, fzpSvcGuarantorCompanyEdit, fzpSvcCompanyDepositEdit, fzpSvcPersonDepositEdit) {
    const my = this;

    _.defaults($scope, {
        canAdd: true,
        canRemove: true,
    })

    //---------------------------------------------------------------------------------------------
    // Депозит ЮЛ/ИП

    my.onDeleteCompanyDeposit = function (item) {
        const i = $scope.model.companyDeposits.indexOf(item);
        $scope.model.companyDeposits.splice(i, 1);
    };

    my.onChangeCompanyDeposit = function (oldItem, newItem) {
        const i = $scope.model.companyDeposits.indexOf(oldItem);
        $scope.model.companyDeposits[i] = newItem;
    };

    my.onCreateCompanyDeposit = function () {
        if (!$scope.model) return;
        fzpSvcCompanyDepositEdit.open({ company: {}, deposit: {} })
            .then(depositInfo => {
                if (!depositInfo) return;
                if (!$scope.model.companyDeposits) $scope.model.companyDeposits = [];
                $scope.model.companyDeposits.push(depositInfo);
            });
    };

    //---------------------------------------------------------------------------------------------
    // Депозит ФЛ

    my.onDeletePersonDeposit = function (item) {
        const i = $scope.model.personDeposits.indexOf(item);
        $scope.model.personDeposits.splice(i, 1);
    };

    my.onChangePersonDeposit = function (oldItem, newItem) {
        const i = $scope.model.personDeposits.indexOf(oldItem);
        $scope.model.personDeposits[i] = newItem;
    };

    my.onCreatePersonDeposit = function () {
        if (!$scope.model) return;
        fzpSvcPersonDepositEdit.open(
            angular.copy({ person: $scope.model.guarantorPersonTemplate.person || {} }),
            $scope.model.guarantorPersonConfig,
            $scope.model.ref,
            $scope.suggestPersons,
            'Депозит ФЛ',
            false
        )
            .then(depositInfo => {
                if (!depositInfo) return;
                if (!$scope.model.personDeposits) $scope.model.personDeposits = [];
                $scope.model.personDeposits.push(depositInfo);
            });
    };

    //---------------------------------------------------------------------------------------------

    my.onDeleteCompany = function (item) {
        const i = $scope.model.guarantorCompanies.indexOf(item);
        $scope.model.guarantorCompanies.splice(i, 1);
    };

    my.onDeletePerson = function (item) {
        const i = $scope.model.guarantorPersons.indexOf(item);
        $scope.model.guarantorPersons.splice(i, 1);
    };

    my.onChangeCompany = function (oldItem, newItem) {
        const i = $scope.model.guarantorCompanies.indexOf(oldItem);
        $scope.model.guarantorCompanies[i] = newItem;
    };

    my.onChangePerson = function (oldItem, newItem) {
        const i = $scope.model.guarantorPersons.indexOf(oldItem);
        $scope.model.guarantorPersons[i] = newItem;
    };

    my.onCreatePerson = function () {
        if (!$scope.model) return;
        fzpSvcPersonEdit.open(
            angular.copy($scope.model.guarantorPersonTemplate.person || {}),
            $scope.model.guarantorPersonConfig,
            $scope.model.ref,
            $scope.suggestPersons,
            'Поручитель',
            false
        )
            .then(person => {
                if (!person) return;
                if (!$scope.model.guarantorPersons) $scope.model.guarantorPersons = [];
                $scope.model.guarantorPersons.push({ person: person });
            });
    };

    my.onCreateCompany = function () {
        if (!$scope.model) return;
        fzpSvcGuarantorCompanyEdit.open($scope.model.guarantorCompanyTemplate, $scope.suggestPersons)
            .then(companyInfo => {
                if (!companyInfo) return;
                if (!$scope.model.guarantorCompanies) $scope.model.guarantorCompanies = [];
                $scope.model.guarantorCompanies.push(companyInfo);
            });
    };
}];

export default ctrl;