import tmpl from './template.html';
import ctrl from './controller';
import './styles.css';

const cmp = function () {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      drafts: '=',
      ngRequired: '=?',
      header: '@',
      canSign: '=?',
      hasChange: '=?',
      isDetached: '=?',
      supportedFiles: '=?',
    },
    template: tmpl,
    controller: ctrl,
    controllerAs: 'my'
  };
};

export default cmp;
