import angular from 'angular';
import fzpCPerson from './fzpCPerson';
import fzpCFounder from './fzpCFounder';
import fzpCFounderCompany from './fzpCFounderCompany';
import fzpSvcFounderCompanyEdit from './fzpSvcFounderCompanyEdit';
import fzpCSubcontractor from './fzpCSubcontractor';
import fzpCSubcontractorCompany from './fzpCSubcontractorCompany';
import fzpSvcSubcontractorCompanyEdit from './fzpSvcSubcontractorCompanyEdit';
import fzpSvcPersonEdit from './fzpSvcPersonEdit';
import fzpCBeneficiaries from './fzpCBeneficiaries';
import fzpCFounders from './fzpCFounders';
import fzpCSubcontractorsList from './fzpCSubcontractorsList';

import fzpCGuarantorsList from './fzpCGuarantorsList';
import fzpCGuarantorCompany from './fzpCGuarantorCompany';
import fzpSvcGuarantorCompanyEdit from './fzpSvcGuarantorCompanyEdit';
import fzpSvcCompanyDepositEdit from './fzpSvcCompanyDepositEdit';
import fzpCGuarantorPerson from './fzpCGuarantorPerson';
import fzpCPersonDeposit from './fzpCPersonDeposit';
import fzpSvcPersonDepositEdit from './fzpSvcPersonDepositEdit';

const moduleName = 'com.farzoom.pear.fzpCPerson';

angular.module(moduleName, [
        'com.farzoom.pear.cAttributes'
    ])
    .service('fzpSvcPersonDepositEdit', fzpSvcPersonDepositEdit)
    .service('fzpSvcCompanyDepositEdit', fzpSvcCompanyDepositEdit)
    .service('fzpSvcGuarantorCompanyEdit', fzpSvcGuarantorCompanyEdit)
    .service('fzpSvcFounderCompanyEdit', fzpSvcFounderCompanyEdit)
    .service('fzpSvcSubcontractorCompanyEdit', fzpSvcSubcontractorCompanyEdit)
    .service('fzpSvcPersonEdit', fzpSvcPersonEdit)
    .directive('fzpCPerson', fzpCPerson)
    .directive('fzpCFounder', fzpCFounder)
    .directive('fzpCFounderCompany', fzpCFounderCompany)
    .directive('fzpCSubcontractor', fzpCSubcontractor)
    .directive('fzpCSubcontractorCompany', fzpCSubcontractorCompany)
    .directive('fzpCBeneficiaries', fzpCBeneficiaries)
    .directive('fzpCFounders', fzpCFounders)
    .directive('fzpCSubcontractorsList', fzpCSubcontractorsList)

    .directive('fzpCGuarantorsList', fzpCGuarantorsList)
    .directive('fzpCGuarantorCompany', fzpCGuarantorCompany)
    .directive('fzpCGuarantorPerson', fzpCGuarantorPerson)
    .directive('fzpCPersonDeposit', fzpCPersonDeposit)
export default moduleName;
