
import angular from 'angular';
import fzpCManualParams from './fzpCVerification';

const moduleName = 'com.farzoom.pear.cVerification';
const comopnentName = 'fzpCVerification';

angular.module(moduleName, [])
    .directive(comopnentName, fzpCManualParams)

export default moduleName;
