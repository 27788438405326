import angular from 'angular';

import fzpCTabs from './fzpCTabs';
import fzpCTab from './fzpCTab';

const moduleName = 'com.farzoom.pear.cTabs';

angular.module(moduleName, [
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCTabs', fzpCTabs)
    .directive('fzpCTab', fzpCTab)

export default moduleName;
