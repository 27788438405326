export class FzpSvcExperience {
    constructor($http) {
        this.$http = $http;
    }

    getExperienceByOrderId(orderId){
        const url = `/api/order/getExperience`;
        const config = {
            params: { orderId }
        }
        return this.$http.get(url, config).then(resp => {
            return resp.data;
        });
    }
}

FzpSvcExperience.$inject = ['$http'];

export default FzpSvcExperience;
