import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';
import _ from 'lodash';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    $scope.$watch('model', () => {
        const item = (($scope.model || {}).data || []).find(x => x.address.addressTypeRefId === 'actualAddress');
        if (item && item.equalsLegalAddress && !item.address.result) {
            fillFromLegalAddress('actualAddress');
        }
    })

    my.onDelete = function (addressType) {
        const item = (($scope.model || {}).data || []).find(x => x.address.addressTypeRefId === addressType);
        if (!item) return;
        const i = $scope.model.data.indexOf(item);
        $scope.model.data.splice(i, 1);
    };

    my.onEdit = function (addressType) {
        const item = (($scope.model || {}).data || []).find(x => x.address.addressTypeRefId === addressType);
        if (item && item.equalsLegalAddress) {
            fillFromLegalAddress(addressType);
        }

        fzModal.open({
            windowClass: 'fz-c-addresses__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item || Object.assign({}, defaults[addressType], ($scope.model || {}).itemTemplate || {}),
                config: ($scope.model || {}).config || {}
            }
        }).closed.then(result => {
            if (!result) return;
            console.log('result', result);
            if (!result.address.result && !result.equalsLegalAddress) {
                // Если не указали адрес, то удаляем его (если он был)
                if (item) {
                    const i = $scope.model.data.indexOf(item);
                    $scope.model.data.splice(i, 1);
                }
            } else {
                // Если указали адрес, добавляем его (или заменяем, если он уже был)
                if (item) {
                    const index = $scope.model.data.indexOf(item);
                    if (index > -1) $scope.model.data[index] = result;
                } else {
                    if (!$scope.model) $scope.model = {};
                    if (!$scope.model.data) $scope.model.data = [];
                    $scope.model.data.push(result);
                }
            }
        });
    };

    my.getAddress = function (addressType) {
        if (!$scope.model || !$scope.model.data) return null;

        return $scope.model.data.find(x => x.address.addressTypeRefId === addressType) || defaults[addressType];
    };

    const fillFromLegalAddress = (type) => {
        const source = my.getAddress('legalAddress');
        const target = (($scope.model || {}).data || []).find(x => x.address.addressTypeRefId === type);
        target.address = _.cloneDeep(source.address, true);
        target.address.addressTypeRefId = type;
        target.addressOwnTypeRefId = source.addressOwnTypeRefId;
    };

    const defaults = {
        'legalAddress': {
            address: {
                addressTypeRefId: 'legalAddress'
            }
        },
        'actualAddress': {
            address: {
                addressTypeRefId: 'actualAddress'
            }
        },
        'postalAddress': {
            address: {
                addressTypeRefId: 'postalAddress'
            }
        },
    }
}];

export default ctrl;