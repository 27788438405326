const defaultConfig = {
    inn: {
        show: true,
        required: false
    },
    birthDate: {
        show: true,
        required: true
    },
    birthPlace: {
        show: true,
        required: true
    },
    identityDocument: {
        show: true,
        required: true
    },
    registrationAddress: {
        show: true,
        required: true
    },
    actualAddress: {
        show: false,
        required: false
    },
    phone: {
        show: true,
        required: false
    },
    email: {
        show: true,
        required: false
    },
    position: {
        show: false,
        required: false
    },
    authorizingDoc: {
        show: false,
        required: false
    },
    citizenship: {
        show: true,
        required: true
    },
    otherCitizenship: {
        show: true,
        required: true
    },
    sharePercent: {
        show: true,
        required: true
    },
    shareSum: {
        show: false,
        required: false
    },
};

const ctrl = ['$scope', function ($scope) {
    const my = this;

    $scope.$watch('model', newValue => {
        if (!newValue || !newValue.person) {
            my.data = null;
            return;
        }
        const founder = angular.copy(newValue);
        const person = founder.person;
        person.personAttributes = founder.personAttributes;
        person.shareSum = founder.shareSum;
        person.sharePercent = founder.sharePercent;
        my.data = person;

        my.config = Object.assign({}, defaultConfig, $scope.config || {});
    });

    my.onPersonModified = function (person) {
        const founder = {
            person: person,
            personAttributes: person.personAttributes,
            shareSum: person.shareSum,
            sharePercent: person.sharePercent
        };
        delete founder.person.personAttributes;
        delete founder.person.shareSum;
        delete founder.person.sharePercent;

        $scope.onChange({
            $item: founder
        });
    }
}];

export default ctrl;
