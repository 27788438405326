import tmpl from './template.html';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            header: '@?'
        },
        template: tmpl
    };
};

export default cmp;
