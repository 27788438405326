import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    width: '83.33%',
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col10 = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}
