import angular from 'angular';
import component from './fzpCExperience';
import service from './fzpSvcExperience';

const moduleName = 'com.farzoom.pear.cExperience';
const componentName = 'fzpCExperience';


angular.module(moduleName, [
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber'
    ])
    .directive(componentName, component)
    .service('fzpSvcExperience', service)

export default moduleName;
