import angular from 'angular';
export class FzpCTabs {
    constructor($root, $scope, $timeout, $element){
        this.$root = $root;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$scope.tabs = [];
    }

    isActive($index){
        return $index === this.$scope.activeTab;
    }

    setActive($index){
        this.$scope.activeTab = $index;
        this.$scope.tabs.forEach(tab => {tab.active = false;});

        const phase = this.$root.$$phase;

        if(phase !== '$apply' && phase !== '$digest') {
            this.$scope.$apply(() => {
                this.$scope.tabs[$index].active = true;
            });
        } else {
            this.$scope.tabs[$index].active = true;
        }
        
    }

    resetTabIndexes(){
        this.$scope.tabs.forEach((tab, index) => {
            tab.index = index;
        });
    }

    addTab(tab, index){ 
        this.$scope.tabs.splice(index, 0, tab);
        const length = this.$scope.tabs.length;
        this.resetTabIndexes();

        if(!tab.disabled && (tab.active || this.$scope.activeTab === undefined)){
            this.$timeout(() => {
                this.setActive(tab.index);
            }, 0);
        }
        if(this.$scope.activeTab !== undefined && this.$scope.tabs[this.$scope.activeTab]){
            this.$timeout(() => {
                this.setActive(this.$scope.activeTab);
            }, 0);
        }
        this.$timeout(() => {
            this.$scope.$apply()
        }, 20);
    }
}

FzpCTabs.$inject = ['$rootScope','$scope', '$timeout'];

export default FzpCTabs;