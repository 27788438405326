const ctrl = function ($scope) {
    const my = this;
    my.state = {
        selectedValue: null
    };

    function equals(model, vm) {
        return !!model && !!vm && model.bankName === vm.bankName && model.bic === vm.BIK && model.corrNumber === vm.corrNumber && model.swift === vm.swift;
    }

    // Order of watches is important! Watch for "model" first.
    $scope.$watch('model', function (newModel) {
        if (!newModel) {
            my.state.selectedValue = null;
        } else if (!equals(newModel, my.state.selectedValue)) {
            my.state.selectedValue = {
                bankName: newModel.bankName,
                BIK: newModel.bic,
                corrNumber: newModel.corrNumber,
                swift: newModel.swift
            };
        }
    });

    $scope.$watch('my.state.selectedValue', function (newValue) {
        if (!newValue) {
            $scope.model = null;
        } else if (!equals($scope.model, newValue)) {
            $scope.model = {
                bankName: newValue.bankName,
                bic: newValue.BIK,
                corrNumber: newValue.corrNumber,
                swift: newValue.swift
            };
        }
    });

    my.formatAccountNumber = function (value) {
        if (!value) return value;

        if (value.length === 20) {
            const p1 = value.substr(0, 5);
            const p2 = value.substr(5, 3);
            const p3 = value.substr(8, 1);
            const p4 = value.substr(9, 4);
            const p5 = value.substr(13);
            value = `${p1}.${p2}.${p3}.${p4}.${p5}`;
        }

        return value;
    };
};

ctrl.$inject = ['$scope'];
export default ctrl;