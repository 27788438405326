import angular from 'angular';
import component from './fzpCInstallment';

const moduleName = 'com.farzoom.cInstallment';
const componentName = 'fzpCInstallment';


angular.module(moduleName, [])
    .directive(componentName, component)

export default moduleName;
