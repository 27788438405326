import React from 'react'
import Icons from './img.svg'

export const Icon = ({
  className,
  iconName,
  onClick,
}) => (
  <svg className={className} onClick={onClick}>
    <use xlinkHref={`${Icons}#${iconName}`} />
  </svg>
)
