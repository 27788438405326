import angular from 'angular';
import cAttribute from './cAttribute';
import cAttributeGroup from './cAttributeGroup';
import cAttributesEditTab from './cAttributesEditTab';
import cAttributesViewTab from './cAttributesViewTab';
import fzSvcAttribute from './fzSvcAttribute';
import fzCAttributeBank from './cAttributeBank';

angular.module('com.farzoom.common.cAttributes', [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts',
        'ui.mask'
    ])
    .directive('fzCAttribute', cAttribute)
    .directive('fzCAttributeBank', fzCAttributeBank)
    .directive('fzCAttributeGroup', cAttributeGroup)
    .directive('fzCAttributesEditTab', cAttributesEditTab)
    .directive('fzCAttributesViewTab', cAttributesViewTab)
    .service('fzSvcAttribute', fzSvcAttribute)
