const ctrl = ['$scope', 'fzpSvcPersonEdit', function ($scope, fzpSvcPersonEdit) {
    const my = this;

    my.onDelete = function (item) {
        const i = $scope.model.data.indexOf(item);
        $scope.model.data.splice(i, 1);
    };

    my.onChange = function (oldItem, newItem) {
        const i = $scope.model.data.indexOf(oldItem);
        $scope.model.data[i] = newItem;
    };

    my.onCreate = function () {
        if (!$scope.model) return;
        fzpSvcPersonEdit.open(
                angular.copy($scope.model.itemTemplate || {}),
                $scope.model.config,
                $scope.model.ref,
                $scope.suggestPersons,
                'Бен. владелец',
                false
            )
            .then(result => {
                if (!result) return;
                if (!$scope.model.data) $scope.model.data = [];
                $scope.model.data.push(result);
            });
    };
}];

export default ctrl;