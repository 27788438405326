import angular from 'angular';

import './styles.css';

export default angular.module('ext.task-forms', [
    require('@farzoom/fz-c-addresses'),
    require('@farzoom/fz-c-attributes'),
    require('@farzoom/fz-c-bank-accounts'),
    require('@farzoom/fz-c-bg-commission'),
    require('@farzoom/fz-c-bki-permission-dates'),
    require('@farzoom/fz-c-comments'),
    require('@farzoom/fz-c-company'),
    require('@farzoom/fz-c-company-beneficiaries'),
    require('@farzoom/fz-c-company-buh'),
    require('@farzoom/fz-c-docs'),
    require('@farzoom/fz-c-employee'),
    require('@farzoom/fz-c-founders'),
    require('@farzoom/fz-c-management'),
    require('@farzoom/fz-c-order'),
    require('@farzoom/fz-c-scoring-result'),
    require('@farzoom/fz-c-select-assignee'),
    require('@farzoom/fz-c-staff-info'),
    require('@farzoom/fz-c-liabilities'),
    require('@farzoom/fz-svc-es'),
    require('@farzoom/fz-c-pledge'),

    // PEAR
    require('./fzp-c-attributes').default,
    require('./fzp-c-company-buh').default,
    require('./fzp-c-bg-commission').default,
    require('./fzp-c-order').default,
    require('./fzp-c-product-bg').default,
    require('./fzp-c-product-bg-edit').default,
    require('./fzp-c-scoring-result').default,
    require('./fzp-c-payments').default,
    require('./fzp-c-installment').default,
    require('./fzp-c-manual-params').default,
    require('./fzp-c-experience').default,
    require('./fzp-form').default,
    require('./fzp-tabs').default,
    require('./fzp-verification').default,
    require('./fzp-c-documents').default,
    require('./fzp-c-multi-editor').default,
    require('./fzp-c-person').default,
    require('./fzp-c-company').default,
    require('./fzp-c-management').default,
    require('./fzp-c-address').default,
    require('./fzp-c-sign-docs').default,
    require('./fzp-c-liabilities').default,
    require('./fzp-c-bills').default,
    require('./fzp-ck-editor').default,
    require('./react-edit-sas').default,
]);
