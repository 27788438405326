import React, {useEffect, useState} from "react";
// import {copyModel} from "./copyModel";
import {createUseStyles} from "react-jss";
import {deepClone} from "../../utils";
import {Col3, Col6} from "../../components/Grid";
import {Fieldset, Input, SwitchSlider} from "../../components/Form";

const useStyles = createUseStyles({
    EditSas: {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .25)',
        padding: 15,
        margin: [-15, -15, 0]
    },
    EditSas__fieldset: {
        paddingBottom: 10,
        borderBottom: '1px dashed #333',
    },
    EditSas__switchSlider: {
        '& > label': {
            fontSize: 12,
            fontWeight: 'normal',
        }
    },
    EditSas__title: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 25
    }
})

export const EditSas = ({model, onChange}) => {
    const classes = useStyles()

    // const [model, onChange] = useState(deepClone(copyModel))
    const [state, setState] = useState(null)

    useEffect(() => {
        if (model?.edit_sasForm?.checks) {
            setState(deepClone(model?.edit_sasForm?.checks))
        }
    }, [model?.edit_sasForm])

    const changeInput = ({name, value, id}) => {
        const newState = state.map(item => {
            if (item?.id === id) {
                item[name] = value
            }
            return item
        })
        setState(newState)
        return newState
    }

    const handleChangeModel = (newState) => {
        const newModel = deepClone(model)
        newModel.edit_sasForm.checks = newState ? [...newState] : [...state]
        onChange(newModel)
    }

    return (
        <div className={classes.EditSas}>
            {
                state &&
                state.map(({text, result, comment, id}) => {
                    return (
                        <Fieldset key={id} className={classes.EditSas__fieldset}>
                            <h2>{text}</h2>
                            <div>
                                <Col3>
                                    <SwitchSlider type='checkbox'
                                                  className={classes.EditSas__switchSlider}
                                                  checked={result}
                                                  label="Выполнение критерия"
                                                  name='result'
                                                  id={`${id}_checkbox`}
                                                  onChange={({
                                                                 target: {
                                                                     name,
                                                                     checked
                                                                 }
                                                             }) => handleChangeModel(changeInput({
                                                      name,
                                                      value: checked,
                                                      id
                                                  }))
                                                  }
                                    />
                                </Col3>
                                <Col6>
                                    <Input
                                        type='text'
                                        name='comment'
                                        value={comment || ''}
                                        label='Обязательный комментарий'
                                        placeholder='-'
                                        onChange={({target: {name, value}}) => changeInput({name, value, id})}
                                        onBlur={() => handleChangeModel(null)}
                                        error={comment?.length < 2 && 'Поле обязательно для заполнения'}
                                    />
                                </Col6>
                            </div>
                        </Fieldset>
                    )
                })
            }
        </div>
    )
}