export class FzpCEditor {
    constructor($scope, element) {
        this.$scope = $scope;
        this.$el = element;
    }

    getTabName(x){
        return (x.current && x.current.countDiffs > 0) ? x.current.countDiffs : '';
    }

}
FzpCEditor.$inject = ['$scope', '$element'];

export default FzpCEditor;