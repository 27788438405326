import angular from 'angular';
import cLiabilitiesEditTab from './cLiabilitiesEditTab';
import cLiabilitiesViewTab from './cLiabilitiesViewTab';
import './styles.css';

const moduleName = 'com.farzoom.pear.cLiabilities';


angular.module(moduleName, [
        'ui.mask',
        'com.farzoom.common.cAttributes',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCLiabilitiesEditTab', cLiabilitiesEditTab)
    .directive('fzpCLiabilitiesViewTab', cLiabilitiesViewTab)


export default moduleName;