const ctrl = ['$scope', 'fzpSvcFounderCompanyEdit', function ($scope, fzpSvcFounderCompanyEdit) {
    const my = this;

    my.showModal = function () {
        fzpSvcFounderCompanyEdit.open(
            $scope.model,
            $scope.config,
            $scope.ref,
            'Юр. лицо / ИП',
            $scope.ngReadonly
        ).then(result => {
            if (!result) return;
            $scope.onChange({
                $item: result
            });
        });
    };

    my.validate = function () {
        if (!$scope.model) return true;
        const cfg = $scope.config || {};
        const required = field => !!cfg[field] && !!cfg[field].required;

        const item = $scope.model;
        return !!item && !!item.company && (!!item.company.id || (!!item.company.INN && !!item.company.OGRN)) &&
            (!required('sharePercent') || !!item.sharePercent) &&
            (!required('shareSum') || !!item.shareSum);
    };
}];

export default ctrl;
