const defaultConfig = {
    inn: {
        show: true,
        required: false
    },
    birthDate: {
        show: true,
        required: true
    },
    birthPlace: {
        show: true,
        required: true
    },
    identityDocument: {
        show: true,
        required: true
    },
    registrationAddress: {
        show: true,
        required: true
    },
    actualAddress: {
        show: false,
        required: false
    },
    phone: {
        show: true,
        required: false
    },
    email: {
        show: true,
        required: false
    },
    position: {
        show: false,
        required: false
    },
    authorizingDoc: {
        show: false,
        required: false
    },
    citizenship: {
        show: true,
        required: true
    },
    otherCitizenship: {
        show: true,
        required: true
    },
    isAttracted: {
        show: true,
        required: true
    }
};

const ctrl = ['$scope', function ($scope) {
    const my = this;

    $scope.$watch('model', newValue => {
        if (!newValue || !newValue.person) {
            my.data = null;
            return;
        }
        const subcontractor = angular.copy(newValue);
        const person = subcontractor.person;
        person.personAttributes = subcontractor.personAttributes;
        person.isAttracted = subcontractor.isAttracted;
        my.data = person;

        my.config = Object.assign({}, defaultConfig, $scope.config || {});
    });

    my.onPersonModified = function (person) {
        const subcontractor = {
            person: person,
            personAttributes: person.personAttributes,
            isAttracted: person.isAttracted
        };
        delete subcontractor.person.personAttributes;
        delete subcontractor.person.isAttracted;

        $scope.onChange({
            $item: subcontractor
        });
    }
}];

export default ctrl;
