export default ['$http', function ($http) {
    var baseUrl = '/camunda/api/engine/engine/default';

    function getCurrentUserId() {
        return $http.get('/camunda/api/admin/auth/user/default').then(function (resp) {
            return resp.data.userId;
        });
    };

    function getUserGroups(userId) {
        return $http.get(`${baseUrl}/group?member=${userId}`).then(function (resp) {
            return resp.data;
        });
    };

    function getCurrentUserGroups() {
        return getCurrentUserId().then(getUserGroups);
    }

    function saveVar(taskId, variableName, variableValue, variableType) {
        var data = {
            value: variableValue,
            type: variableType || 'String'
        };

        return $http.put(`${baseUrl}/task/${taskId}/variables/${variableName}`, data);
    };

    function getProcessInstance(orderId) {
        return $http.get(`${baseUrl}/history/process-instance?processInstanceBusinessKey=${orderId}`).then(function (resp) {
            if (!resp.data || !resp.data.length) throw new Error('Process instance not found');
            return resp.data[0];
        });
    };

    function getVariablesByPid(processInstanceId) {
        return $http.get(`${baseUrl}/history/variable-instance?processInstanceId=${processInstanceId}`).then(function (resp) {
            return resp.data;
        });
    };

    function getVariableValueByPid(processInstanceId, variableName) {
        return $http.get(`${baseUrl}/history/variable-instance?processInstanceId=${processInstanceId}&variableName=${variableName}`).then(function (resp) {
            return resp.data[0].value;
        });
    };

    function getVars(orderId) {
        return getProcessInstance(orderId).then(function (processInstance) {
            return processInstance.id;
        }).then(getVariablesByPid);
    };

    function getVar(orderId, variableName) {
        return getProcessInstance(orderId).then(function (processInstance) {
            return processInstance.id;
        }).then(pid => getVariableValueByPid(pid, variableName));
    };

    function setVar(camForm, name, value, type) {
        console.log(`svc.camunda.setVar(): "${name}"=${JSON.stringify(value)}`);

        const vm = camForm.variableManager;
        const v = vm.variables[name];
        if (!v) {
            vm.createVariable({
                name: name,
                type: type,
                value: value,
                isDirty: true
            });
        } else {
            if (!v.type || v.type === "Null") v.type = type;
            if (v.type === 'Json') {
                v.value = value;
            } else {
                vm.variableValue(name, value);
            }
        }
    };

    this.getCurrentUserId = getCurrentUserId;
    this.getUserGroups = getUserGroups;
    this.getCurrentUserGroups = getCurrentUserGroups;
    this.getProcessInstance = getProcessInstance;
    this.getVariablesByPid = getVariablesByPid;
    this.getVars = getVars;
    this.getVar = getVar;
    this.saveVar = saveVar;
    this.setVar = setVar;
}];
