import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  root: {
    width: '75%',
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

export const Col9 = ({ children, className }) => {
  const classes = useStyles()
  return <div className={classnames(classes.root, className)}>{children}</div>
}
