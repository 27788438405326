import angular from 'angular';
import cBgCommission from './cBgCommission';

const moduleName = 'com.farzoom.pear.cBgCommission';

angular.module(moduleName, [
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
    ])
    .directive('fzpCBgCommission', cBgCommission);

export default moduleName;
