import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.data = angular.copy($modalParams.item);
    my.config = angular.copy($modalParams.config);
    my.readonly = $modalParams.readonly;
    my.adressOwnTypeRequired = (my.config[`${my.data.address.addressTypeRefId}OwnType`] || {}).required || false;
    console.log('modalka', my.data);

    my.closeOk = function () {
        if (my.data.equalsLegalAddress) {
            if (my.data.address) {
                my.data.address = {
                    addressTypeRefId: my.data.address.addressTypeRefId
                };
            }
            delete my.data.addressOwnTypeRefId;
        }
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.state = {
        addressTypeRefId: null
    };

    $scope.$watch('my.data.address.addressTypeRefId', function (addressTypeRefId) {
        if (!addressTypeRefId) return;
        my.state.addressTypeRefId = addressTypeRefId;
    });

    $scope.$watch('my.data.address', function (address) {
        if (!address) return;
        if (!address.addressTypeRefId) address.addressTypeRefId = my.state.addressTypeRefId;
    });
}];
