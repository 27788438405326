import angular from 'angular';
import cAttribute from './cAttribute';
import cAttributeGroup from './cAttributeGroup';
import cAttributes from './cAttributes';
import fzSvcAttribute from './fzSvcAttribute';
import fzCAttributeBank from './cAttributeBank';
import cAttributeGroupDetails from './cAttributeGroupDetails';
import dPatternValidation from './dPatternValidator';

const moduleName = 'com.farzoom.pear.cAttributes';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts',
        'ui.mask'
    ])
    .directive('fzpCAttribute', cAttribute)
    .directive('fzpCAttributeBank', fzCAttributeBank)
    .directive('fzpCAttributeGroup', cAttributeGroup)
    .directive('fzpCAttributes', cAttributes)
    .directive('fzpCAttributeGroupDetails', cAttributeGroupDetails)
    .service('fzpSvcAttribute', fzSvcAttribute)
    .directive('fzpPattern', dPatternValidation)

export default moduleName;
