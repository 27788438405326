import initialData from './data.json';
const defaultConfig = {
    inn: {
        show: true,
        required: false
    },
    birthDate: {
        show: true,
        required: true
    },
    birthPlace: {
        show: true,
        required: true
    },
    identityDocument: {
        show: true,
        required: true
    },
    registrationAddress: {
        show: true,
        required: true
    },
    actualAddress: {
        show: false,
        required: false
    },
    phone: {
        show: true,
        required: false
    },
    email: {
        show: true,
        required: false
    },
    position: {
        show: false,
        required: false
    },
    authorizingDoc: {
        show: false,
        required: false
    },
    citizenship: {
        show: true,
        required: false
    },
    otherCitizenship: {
        show: false,
        required: false
    },
    isAttracted: {
            show: true,
            required: true
    }
};

const ctrl = ['$scope', 'fzpSvcPersonEdit', 'fzpSvcSubcontractorCompanyEdit', function ($scope, fzpSvcPersonEdit, fzpSvcSubcontractorCompanyEdit) {
    const my = this;

    my.onDelete = function (item) {
        const i = $scope.model.data.indexOf(item);
        $scope.model.data.splice(i, 1);
    };

    my.onChange = function (oldItem, newItem) {
        const i = $scope.model.data.indexOf(oldItem);
        $scope.model.data[i] = newItem;
    };

    my.onCreatePerson = function () {
        if (!$scope.model) {
            //Если модель undefined то передадим пустую структуру модели
            $scope.model=initialData;
        }
        const config = Object.assign({}, defaultConfig, $scope.model.config || {});
        fzpSvcPersonEdit.open(
                angular.copy($scope.model.personItemTemplate || {}),
                config,
                $scope.model.ref,
                $scope.suggestPersons,
                'Субподрядчик',
                false
            )
            .then(person => {
                if (!person) {
                    return;
                }

                const subcontractor = {
                    person: person,
                    personAttributes: person.personAttributes,
                    isAttracted: person.isAttracted
                };
                delete subcontractor.person.personAttributes;

                if (!$scope.model.data) {
                    $scope.model.data = [];
                }
                $scope.model.data.push(subcontractor);
            });
    };

    my.onCreateCompany = function () {
        if (!$scope.model) {
            //Если модель undefined то передадим пустую структуру модели
            $scope.model=initialData;
        }

        const config = Object.assign({}, defaultConfig, $scope.model.config || {});
        fzpSvcSubcontractorCompanyEdit.open(
                {},
                config,
                $scope.model.ref,
                'Субподрядчик',
                false
            )
            .then(cubcontractorCompany => {
                if (!cubcontractorCompany) {
                    return;
                }
                if (!$scope.model.data) {
                    $scope.model.data = [];
                }
                $scope.model.data.push(cubcontractorCompany);
            });
    };
}];

export default ctrl;