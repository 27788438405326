import angular from 'angular';
import cScoringResult from './cScoringResult';

const moduleName = 'com.farzoom.pear.cScoringResult';

angular.module(moduleName, [
        'ngSanitize',
        'com.farzoom.common.fzDTmpl',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCScoringResult', cScoringResult);
    
export default moduleName;