import _ from 'lodash';

const ctrl = function ($scope, fzSvcAttribute) {
    const my = this;

    my.model = {};
    my.attributeContext = null;

    my.state = {
        model: _.cloneDeep($scope.model),
    }

    my.evaluateExpression = function (expressionString) {
        if (!my.attributeContext) return;
        const hasChange = !_.isEqual($scope.model, my.state.model);
        if ($scope.hasChange !== hasChange) {
            $scope.hasChange = hasChange;
        }
        return fzSvcAttribute.evaluateExpression(expressionString, my.attributeContext);
    };

    $scope.$watch('model', function (model) {
        if (model) {
            my.attributeContext = fzSvcAttribute.createContext(model);
            if (model.attributes) {
                my.model = model.attributes;
            } else {
                my.model = model;
            }
        }
    });
};

ctrl.$inject = ['$scope', 'fzpSvcAttribute'];
export default ctrl;
