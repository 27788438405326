import angular from 'angular';
import component from './fzpCSignDocs';

const moduleName = 'com.farzoom.pear.cSignDocs';
const componentName = 'fzpCSignDocs';


angular.module(moduleName, [
        'com.farzoom.common.fzFiles'
    ])
    .directive(componentName, component)

export default moduleName;
