import tmpl from './template.html';
import ctrl from './controller';
import './styles.css';

const cmp = [function () {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            hasChange: '=?',
            ngReadonly: '=?',
            ngRequired: '=?',
        },
        template: tmpl,
        controller: ctrl,
        controllerAs: 'my'
    };
}];

export default cmp;
