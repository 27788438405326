import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  col8: {
    width: '66.66%',
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
})

export const Col8 = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.col8}>{children}</div>
}
