import modalTmpl from '../cBillsEditTab/modal/template.html';
import modalCtrl from '../cBillsEditTab/modal/controller'

const defaultScopeOptions = {
    showTypeIcon: false,
    showInfo: false,
    showIssuer: true,
    showIssuedDate: true,
    showAmount: true
}

const setOptionsIfNotExits = (target, options) => {
    for(let key in options){
        if(!_.has(target, key)){
            _.set(target, key, _.get(options, key));
        }
    }
}
const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;
    setOptionsIfNotExits($scope, defaultScopeOptions);
    
    my.showDetails = function (item) {
        fzModal.open({
            windowClass: 'fz-c-liabilities__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item,
                ref: $scope.model.ref,
                readonly: true
            }
        });
    };

    my.getRefName = function (refName, value) {
        if (!value) return 'нет сведений';
        const m = $scope.model;
        if (!m || !m.ref || !m.ref[refName]) return null;
        const refItem = m.ref[refName].find(x => x.value === value) || {};
        return refItem.name;
    };

    my.getLetter = function (item) {
        const name = my.getRefName('productType', item.productTypeRefId);
        return name.toUpperCase().split(' ').map(x => x.substr(0, 1)).join('');
    };
}];

export default ctrl;